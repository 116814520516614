import { getLevel } from "../../levels"
var times = require('lodash.times')

const defaultMatrix = (level) => {
	return {
		grid: times(8).map(row => times(16).map(col => {
			return (row*16)+(col+1) < level*8 ? 1 : 0
		}))
	}
}

export const inMatrix = (matrix, row, col) => {
	return matrix[row] && matrix[row][col]
}

export default (level) => {
	return (getLevel(level) || defaultMatrix(level)).grid
}
