
import invader from "../entities/rendered-invader"
import moveInvaders from "./invaders/move"
import initInvaders from "./invaders/init"
import invadersReachedHero from "./invaders/invaders-reached-hero"

import {
	EVENT_GAME_OVER
} from "../constants"

let started = false

export default function Invaders({ width, height, alreadyKilled, level }) {
	return function Invaders(entities, { input, dispatch }) {

		if (!started) {
			started = true
			initInvaders(entities, { width, height, entityBuilder: invader, alreadyKilled, level })
		}

		if (entities.invaders.length === 0) {
			started = false
		}

		if (started && entities.invaders.length !== 0) {
			moveInvaders(entities, { width, height })
		}

		if (entities.invaders.length !== 0 && invadersReachedHero(entities)) {
			dispatch({
				type: EVENT_GAME_OVER,
			})
		}

		return entities
	}
}
