import {
	E_MONSTER,
	INVADER_WIDTH,
	INVADER_WIDTH_RATIO,
	INVADER_HEIGHT,
	DIRECTION_LEFT
} from "../constants"

import entity from "./entity"
import typology from '../components/typologies/monster'
import active from '../components/active'
import position from "../components/position"
import direction from "../components/direction"
import velocity from "../components/velocity"
import dimensions from "../components/dimensions"
import borders from "../components/borders"
import playground from "../components/playground"

export default function monster({ id, playgroundDimensions, monsterVelocity = 10, monsterWidth, monsterHeight, monsterTypology }) {

	const { width } = playgroundDimensions

	const monsterDimensions = {
		width: 45, //monsterWidth || width * INVADER_WIDTH_RATIO,
		height: 18.5 //monsterHeight || width * INVADER_WIDTH_RATIO * (INVADER_HEIGHT / INVADER_WIDTH)
	}

	return entity(E_MONSTER, id)
		.addComponent(active, false)
		.addComponent(position, { x: 520, y: 55 })
		.addComponent(direction, DIRECTION_LEFT)
		.addComponent(velocity, monsterVelocity)
		.addComponent(dimensions, monsterDimensions)
		.addComponent(typology, monsterTypology)
		.addComponent(borders)
		.addComponent(playground, playgroundDimensions)
}
