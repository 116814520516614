
export function onKeyDown(key, input) {
  return !!input.find(x => {
		if (x.name !== "onKeyDown") {
			return false
		}

		if (x.payload.key !== key) {
			return false
		}

		return true
	})
}

export function onKeyUp(key, input) {
  return !!input.find(x => {
		if (x.name !== "onKeyUp") {
			return false
		}

		if (x.payload.key !== key) {
			return false
		}

		return true
	})
}
