/*class Component {
	constructor(entity, { typology, popTime, score }) {

		this.score = score
	}
}*/

export default function typology(entity, typology) {
	entity.typology = typology //new Component(entity, typology)
	return entity
}
