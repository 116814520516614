
import React, { PureComponent } from "react"

class Invader extends PureComponent {
	render() {
		const entity = this.props.renderer.props

		const x = this.props.x - entity.dimensions.width / 2
		const y = this.props.y - entity.dimensions.height / 2

		return (
			<div style={{
					position: "absolute",
					width: entity.dimensions.width,
					height: entity.dimensions.height,
					left: x,
					top: y,
				}}
				className="invader-block"
				dangerouslySetInnerHTML={{ __html: entity.typology.picto }}
			/>
		);
	}
}

export default Invader
