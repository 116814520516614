
import {
	E_HERO
} from "../constants"

import entity from "./entity"
import position from "../components/position"
import direction from "../components/direction"
import velocity from "../components/velocity"
import dimensions from "../components/dimensions"
import playground from "../components/playground"
import bonus from "../components/bonus"

export default function hero({ width, height }) {
	const heroDimensions = {
		width: 45, //width * INVADER_WIDTH_RATIO,
		height: 18.5 //width * INVADER_WIDTH_RATIO * (HERO_HEIGHT / HERO_WIDTH)
	}

	const heroPosition = {
		y: height - heroDimensions.height / 2 - 20,
		x: width / 2
	}

	return entity(E_HERO)
		.addComponent(position, heroPosition)
		.addComponent(direction)
		.addComponent(velocity)
		.addComponent(dimensions, heroDimensions)
		.addComponent(bonus, {})
		.addComponent(playground, { width, height })
}
