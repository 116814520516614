
class Component {
	constructor(type, id) {
		this.type = type
		this.id = id
	}

	addComponent(component, configuration) {
		component(this, configuration)

		return this
	}
}

export default function component(type, id) {
	return new Component(type, id)
}
