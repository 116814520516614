
import React, { PureComponent } from "react"

class Monster extends PureComponent {
	render() {
		const entity = this.props.renderer.props

		const x = this.props.x - entity.dimensions.width / 2
		const y = this.props.y - entity.dimensions.height / 2

		if (x > 480) return null

		return (
			<div style={{
					position: "absolute",
					width: entity.dimensions.width,
					height: entity.dimensions.height,
					left: x,
					top: y,
					opacity: entity.typology.touches.length > 0 ? 0.5 : 1
				}}
				className="monster-block"
				dangerouslySetInnerHTML={{ __html: entity.typology.picto || defaultPicto }}
			/>
		);
	}
}

const defaultPicto = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 37" style="fill: #fff; opacity: 1}"><path d="M28,31.37,20.64,24H28V14A14,14,0,1,0,0,14V24H7.36L0,31.37,5.63,37,14,28.62,22.37,37ZM20,16H8V14A6,6,0,1,1,20,14Z"/></svg>'

export default Monster
