import {
	E_HERO,
	KEY_RIGHT,
	KEY_LEFT,
	KEY_S,
	DIRECTION_RIGHT,
	DIRECTION_LEFT,
	DIRECTION_NONE,

	EVENT_LEVEL_FINISHED,

	ENV_DEV
} from "../constants"

import { onKeyDown, onKeyUp } from "./events"

require('dotenv').config()

export default function hero(entities, { input, dispatch }) {

	const hero = entities[E_HERO]

	// FIXME Improve movement when we switch from left to right or right to left
	// releasing the previous key just after pushing the next key.
	// For example : I press left key, I begin to press right key and release
	// left key only few milliseconds later

	if (onKeyDown(KEY_RIGHT, input)) {
		hero.direction = DIRECTION_RIGHT
	}

	if (onKeyDown(KEY_LEFT, input)) {
		hero.direction = DIRECTION_LEFT
	}

	if (process.env.NODE_ENV === ENV_DEV) {
		if (onKeyDown(KEY_S, input)) {
			dispatch({
				type: EVENT_LEVEL_FINISHED,
			})
		}
	}

	if (hero.direction !== DIRECTION_NONE && (onKeyUp(KEY_RIGHT, input) || onKeyUp(KEY_LEFT, input))) {
		hero.direction = DIRECTION_NONE
	}

	let { x } = hero
	const { direction, dimensions: { width }, playground: { width: playgroundWidth }} = hero

	switch(direction) {
		case DIRECTION_RIGHT:
			if ((x + width / 2) < playgroundWidth - 10) {
				x = x + hero.velocity
			}

			break
		case DIRECTION_LEFT:
			if ((x - width / 2) > 10) {
				x = x - hero.velocity
			}
			break
		default:
			break
	}

	hero.x = x

	return entities
}
