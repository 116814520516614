import React, { useEffect, useState } from 'react'

export default ({life, onNext}) => {

	const [ waitingFor, setWaitingFor ] = useState(true)

	useEffect(() => {
		setTimeout(() => {
			setWaitingFor(false)
		}, 2000)
		return () => {
			document.removeEventListener("keydown", handleKeyPressed, false)
		}
	}, [])

	useEffect(() => {
		if (!waitingFor) {
			document.addEventListener("keydown", handleKeyPressed, false)
		} else {
			document.removeEventListener("keydown", handleKeyPressed, false)
		}
	}, [waitingFor])

	const handleKeyPressed = (event) => {
		if (event.code === "Space") {
			document.removeEventListener("keydown", handleKeyPressed, false)
			onNext()
		}
	}

	return (
		<div style={{position: "relative", color: '#fff', display: "flex", flexDirection: "column", alignItems: "center"}}>
			<span style={{fontSize: "2.5em", display: "flex", alignItems: "center"}}>
				YOU LOSE&nbsp;<strong>1</strong>
				<div style={{marginRight: "10px", marginLeft: "10px", display: "flex"}}>
					<svg width="40" height="40" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
						<path d="M90.35,16.05c-11.66,0-21.81,6.97-26.35,17.06c-4.54-10.08-14.69-17.06-26.35-17.06 c-15.92,0-28.87,12.96-28.87,28.88c0,35.9,51.79,65.46,54,66.7c0.38,0.21,0.79,0.32,1.21,0.32c0.42,0,0.84-0.11,1.21-0.32 c2.2-1.24,54.01-30.8,54.01-66.7C119.22,29.01,106.27,16.05,90.35,16.05z" style={{fill:"#DB4437"}}/>
					</svg>
				</div>
			</span>
			<div style={{display: "flex", fontSize: "1.5em", alignItems: "center", padding: "20px"}}>
				{life} 
				<div style={{marginRight: "10px", marginLeft: "10px", display: "flex"}}>
					<svg width="40" height="40" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
						<path d="M90.35,16.05c-11.66,0-21.81,6.97-26.35,17.06c-4.54-10.08-14.69-17.06-26.35-17.06 c-15.92,0-28.87,12.96-28.87,28.88c0,35.9,51.79,65.46,54,66.7c0.38,0.21,0.79,0.32,1.21,0.32c0.42,0,0.84-0.11,1.21-0.32 c2.2-1.24,54.01-30.8,54.01-66.7C119.22,29.01,106.27,16.05,90.35,16.05z" style={{fill:"#DB4437"}}/>
					</svg>
				</div>
				remaining
			</div>
			<div style={{padding: "20px", height: 23}}>
				{ !waitingFor && (
						<>
							Press <strong>SPACE</strong> to continue
						</>
				)}
			</div>
		</div>
	)
}
