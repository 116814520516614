import React, { useEffect, useState } from "react"
import { setConfig, baseConfig } from './config'

import "./Home.css"

export default ({width, height, play, highScores}) => {

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		//if (process.env.NODE_ENV == "development") {
		//	setLoading(false)
		//} else {
			fetch("https://codekraft.s3.eu-west-3.amazonaws.com/spaceinvaders/config.json", {
				headers: {
					"Content-Type": "application/json"
				}
			})
				.then(response => response.json())
				.then(cfg => setConfig(cfg))
				.then(() => setLoading(false))
				.catch(e => console.log(e))
		//}
	}, [])

	useEffect(() => {
		if (!loading) {
			document.addEventListener("keydown", handleKeyPressed, false)
			return () => {
				document.removeEventListener("keydown", handleKeyPressed, false)
			}
		}
	}, [loading])

	const handleKeyPressed = (event) => {
		if (event.code === "Space") {
			play()
		}
		if (event.code === "KeyH") {
			highScores()
		}
	}

	return (
		<div style={{width, height, background: "transparent", color: "white", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}} onKeyPress={handleKeyPressed}>
			<Splash />
			<div>
				{ loading
					? <span>Loading</span>
					: <>Press <b>SPACE</b> to play / <b>H</b> for High Scores</>
				}
			</div>
		</div>
	)
}

const Splash = () => (
	<svg id="logo" xmlns="http://www.w3.org/2000/svg" width="520" height="420" viewBox="0 0 1200 1200">
		<path d="M102.25,92.93h-37V86.71a18.49,18.49,0,0,1,37,0ZM83.76,43.87A42.84,42.84,0,0,0,40.92,86.71v30.57H63.45L40.92,139.81,58.14,157l25.62-25.62L109.39,157l17.21-17.22-22.52-22.53H126.6V86.71A42.84,42.84,0,0,0,83.76,43.87Z" style={{fill: "#fff"}}/>
		<path d="M231.46,92.93h-37V86.71a18.49,18.49,0,0,1,37,0ZM213,43.87a42.84,42.84,0,0,0-42.84,42.84v30.57h22.52l-22.52,22.53L187.35,157,213,131.41,238.59,157l17.22-17.22-22.53-22.53h22.53V86.71A42.84,42.84,0,0,0,213,43.87Z" style={{fill: "#fff"}}/>
		<path d="M360.66,92.93h-37V86.71a18.49,18.49,0,0,1,37,0ZM342.17,43.87a42.84,42.84,0,0,0-42.84,42.84v30.57h22.53l-22.53,22.53L316.55,157l25.62-25.62L367.8,157,385,139.81l-22.53-22.53H385V86.71A42.85,42.85,0,0,0,342.17,43.87Z" style={{fill: "#fff"}}/>
		<path d="M489.87,92.93h-37V86.71a18.49,18.49,0,0,1,37,0ZM471.38,43.87a42.84,42.84,0,0,0-42.84,42.84v30.57h22.52l-22.52,22.53L445.76,157l25.62-25.62L497,157l17.22-17.22-22.53-22.53h22.53V86.71A42.84,42.84,0,0,0,471.38,43.87Z" style={{fill: "#fff"}}/>
		<path d="M619.07,92.93h-37V86.71a18.49,18.49,0,0,1,37,0ZM600.58,43.87a42.84,42.84,0,0,0-42.84,42.84v30.57h22.53l-22.53,22.53L575,157l25.62-25.62L626.21,157l17.22-17.22L620.9,117.28h22.53V86.71A42.85,42.85,0,0,0,600.58,43.87Z" style={{fill: "#fff"}}/>
		<path d="M748.28,92.93h-37V86.71a18.49,18.49,0,0,1,37,0ZM729.79,43.87A42.84,42.84,0,0,0,687,86.71v30.57h22.52L687,139.81,704.17,157l25.62-25.62L755.41,157l17.22-17.22L750.1,117.28h22.53V86.71A42.84,42.84,0,0,0,729.79,43.87Z" style={{fill: "#fff"}}/>
		<path d="M877.48,92.93h-37V86.71a18.49,18.49,0,0,1,37,0ZM859,43.87a42.84,42.84,0,0,0-42.84,42.84v30.57h22.53l-22.53,22.53L833.37,157,859,131.41,884.62,157l17.22-17.22-22.53-22.53h22.53V86.71A42.84,42.84,0,0,0,859,43.87Z" style={{fill: "#fff"}}/>
		<path d="M1006.69,92.93h-37V86.71a18.49,18.49,0,0,1,37,0ZM988.2,43.87a42.84,42.84,0,0,0-42.84,42.84v30.57h22.52l-22.52,22.53L962.58,157l25.62-25.62L1013.82,157,1031,139.81l-22.53-22.53H1031V86.71A42.84,42.84,0,0,0,988.2,43.87Z" style={{fill: "#fff"}}/>
		<path d="M1135.89,92.93h-37V86.71a18.49,18.49,0,1,1,37,0Zm24.36-6.22a42.85,42.85,0,0,0-85.69,0v30.57h22.53l-22.53,22.53L1091.78,157l25.62-25.62L1143,157l17.22-17.22-22.53-22.53h22.53Z" style={{fill: "#fff"}}/>
		<path d="M102.25,242.52h-37V236.3a18.49,18.49,0,1,1,37,0ZM83.76,193.46A42.84,42.84,0,0,0,40.92,236.3v30.57H63.45L40.92,289.4l17.22,17.22L83.76,281l25.63,25.62L126.6,289.4l-22.52-22.53H126.6V236.3A42.84,42.84,0,0,0,83.76,193.46Z" style={{fill: "#fff"}}/>
		<path d="M231.46,242.52h-37V236.3a18.49,18.49,0,0,1,37,0ZM213,193.46a42.84,42.84,0,0,0-42.84,42.84v30.57h22.52L170.13,289.4l17.22,17.22L213,281l25.62,25.62,17.22-17.22-22.53-22.53h22.53V236.3A42.84,42.84,0,0,0,213,193.46Z" style={{fill: "#fff"}}/>
		<path d="M360.66,242.52h-37V236.3a18.49,18.49,0,0,1,37,0Zm-18.49-49.06a42.84,42.84,0,0,0-42.84,42.84v30.57h22.53L299.33,289.4l17.22,17.22L342.17,281l25.63,25.62L385,289.4l-22.53-22.53H385V236.3A42.85,42.85,0,0,0,342.17,193.46Z" style={{fill: "#fff"}}/>
		<path d="M489.87,242.52h-37V236.3a18.49,18.49,0,0,1,37,0Zm-18.49-49.06a42.84,42.84,0,0,0-42.84,42.84v30.57h22.52L428.54,289.4l17.22,17.22L471.38,281,497,306.62l17.22-17.22-22.53-22.53h22.53V236.3A42.84,42.84,0,0,0,471.38,193.46Z" style={{fill: "#fff"}}/>
		<path d="M619.07,242.52h-37V236.3a18.49,18.49,0,0,1,37,0Zm-18.49-49.06a42.84,42.84,0,0,0-42.84,42.84v30.57h22.53L557.74,289.4,575,306.62,600.58,281l25.63,25.62,17.22-17.22L620.9,266.87h22.53V236.3A42.85,42.85,0,0,0,600.58,193.46Z" style={{fill: "#fff"}}/>
		<path d="M748.28,242.52h-37V236.3a18.49,18.49,0,1,1,37,0Zm-18.49-49.06A42.84,42.84,0,0,0,687,236.3v30.57h22.52L687,289.4l17.22,17.22L729.79,281l25.62,25.62,17.22-17.22L750.1,266.87h22.53V236.3A42.84,42.84,0,0,0,729.79,193.46Z" style={{fill: "#fff"}}/>
		<path d="M877.48,242.52h-37V236.3a18.49,18.49,0,1,1,37,0ZM859,193.46a42.84,42.84,0,0,0-42.84,42.84v30.57h22.53L816.15,289.4l17.22,17.22L859,281l25.63,25.62,17.22-17.22-22.53-22.53h22.53V236.3A42.84,42.84,0,0,0,859,193.46Z" style={{fill: "#fff"}}/>
		<path d="M1006.69,242.52h-37V236.3a18.49,18.49,0,1,1,37,0ZM988.2,193.46a42.84,42.84,0,0,0-42.84,42.84v30.57h22.52L945.36,289.4l17.22,17.22L988.2,281l25.62,25.62L1031,289.4l-22.53-22.53H1031V236.3A42.84,42.84,0,0,0,988.2,193.46Z" style={{fill: "#fff"}}/>
		<path d="M1135.89,242.52h-37V236.3a18.49,18.49,0,1,1,37,0Zm24.36-6.22a42.85,42.85,0,0,0-85.69,0v30.57h22.53l-22.53,22.53,17.22,17.22L1117.4,281,1143,306.62l17.22-17.22-22.53-22.53h22.53Z" style={{fill: "#fff"}}/>
		<path d="M102.25,392.11h-37v-6.22a18.49,18.49,0,0,1,37,0ZM83.76,343.05a42.84,42.84,0,0,0-42.84,42.84v30.57H63.45L40.92,439l17.22,17.22,25.62-25.63,25.63,25.63L126.6,439l-22.52-22.53H126.6V385.89A42.84,42.84,0,0,0,83.76,343.05Z" style={{fill: "#fff"}}/>
		<path d="M231.46,392.11h-37v-6.22a18.49,18.49,0,0,1,37,0ZM213,343.05a42.84,42.84,0,0,0-42.84,42.84v30.57h22.52L170.13,439l17.22,17.22L213,430.58l25.62,25.63L255.81,439l-22.53-22.53h22.53V385.89A42.84,42.84,0,0,0,213,343.05Z" style={{fill: "#fff"}}/>
		<path d="M360.66,392.11h-37v-6.22a18.49,18.49,0,0,1,37,0Zm-18.49-49.06a42.84,42.84,0,0,0-42.84,42.84v30.57h22.53L299.33,439l17.22,17.22,25.62-25.63,25.63,25.63L385,439l-22.53-22.53H385V385.89A42.85,42.85,0,0,0,342.17,343.05Z" style={{fill: "#fff"}}/>
		<path d="M489.87,392.11h-37v-6.22a18.49,18.49,0,0,1,37,0Zm-18.49-49.06a42.84,42.84,0,0,0-42.84,42.84v30.57h22.52L428.54,439l17.22,17.22,25.62-25.63L497,456.21,514.22,439l-22.53-22.53h22.53V385.89A42.84,42.84,0,0,0,471.38,343.05Z" style={{fill: "#fff"}}/>
		<path d="M619.07,392.11h-37v-6.22a18.49,18.49,0,0,1,37,0Zm-18.49-49.06a42.84,42.84,0,0,0-42.84,42.84v30.57h22.53L557.74,439,575,456.21l25.62-25.63,25.63,25.63L643.43,439,620.9,416.46h22.53V385.89A42.85,42.85,0,0,0,600.58,343.05Z" style={{fill: "#fff"}}/>
		<path d="M877.48,392.11h-37v-6.22a18.49,18.49,0,0,1,37,0ZM859,343.05a42.84,42.84,0,0,0-42.84,42.84v30.57h22.53L816.15,439l17.22,17.22L859,430.58l25.63,25.63L901.84,439l-22.53-22.53h22.53V385.89A42.84,42.84,0,0,0,859,343.05Z" style={{fill: "#fff"}}/>
		<path d="M1006.69,392.11h-37v-6.22a18.49,18.49,0,0,1,37,0ZM988.2,343.05a42.84,42.84,0,0,0-42.84,42.84v30.57h22.52L945.36,439l17.22,17.22,25.62-25.63,25.62,25.63L1031,439l-22.53-22.53H1031V385.89A42.84,42.84,0,0,0,988.2,343.05Z" style={{fill: "#fff"}}/>
		<path d="M1135.89,392.11h-37v-6.22a18.49,18.49,0,1,1,37,0Zm24.36-6.22a42.85,42.85,0,0,0-85.69,0v30.57h22.53L1074.56,439l17.22,17.22,25.62-25.63L1143,456.21,1160.25,439l-22.53-22.53h22.53Z" style={{fill: "#fff"}}/>
		<path d="M102.25,541.7h-37v-6.22a18.49,18.49,0,0,1,37,0ZM83.76,492.64a42.84,42.84,0,0,0-42.84,42.84v30.57H63.45L40.92,588.58,58.14,605.8l25.62-25.63,25.63,25.63,17.21-17.22-22.52-22.53H126.6V535.48A42.84,42.84,0,0,0,83.76,492.64Z" style={{fill: "#fff"}}/>
		<path d="M489.87,541.7h-37v-6.22a18.49,18.49,0,0,1,37,0Zm-18.49-49.06a42.84,42.84,0,0,0-42.84,42.84v30.57h22.52l-22.52,22.53,17.22,17.22,25.62-25.63L497,605.8l17.22-17.22-22.53-22.53h22.53V535.48A42.84,42.84,0,0,0,471.38,492.64Z" style={{fill: "#fff"}}/>
		<path d="M1135.89,541.7h-37v-6.22a18.49,18.49,0,0,1,37,0Zm24.36-6.22a42.85,42.85,0,0,0-85.69,0v30.57h22.53l-22.53,22.53,17.22,17.22,25.62-25.63L1143,605.8l17.22-17.22-22.53-22.53h22.53Z" style={{fill: "#fff"}}/>
		<g>
			<polygon points="695.18 1110.93 630.01 1110.93 630.01 1092.54 609.3 1092.54 609.3 1072.44 591.86 1072.44 591.86 1092.54 571.16 1092.54 571.16 1110.93 505.99 1110.93 492.97 1125.48 492.97 1160.6 708.2 1160.6 708.2 1125.48 695.18 1110.93" style={{fill: "#fff"}}/>
			<rect x="593.82" y="891.21" width="13.53" height="52.16" style={{fill: "#fff"}}/>
		</g>
	</svg>
)
