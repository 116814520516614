
import { findBottomInvaders } from "./move"

import {
	E_HERO,
} from "../../constants"

export default function invadersReachedHero(entities) {
	const hero = entities[E_HERO]
	const invaders = entities.invaders

	const bottomInvader = findBottomInvaders(invaders)

	if (hero.y - hero.dimensions.height / 2 <= bottomInvader.y + bottomInvader.dimensions.height) {
		return true
	}

	return false
}
