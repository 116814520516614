
class Position {
	constructor(entity, { x, y }) {
		this.entity = entity
		this.entity.x = x
		this.entity.y = y
	}
}

export default function position(entity, position) {
	entity.position = new Position(entity, position)

	return entity
}
