import React, { useState } from 'react'
import { GameEngine } from 'react-game-engine'
import LevelFinished from './LevelFinished'
import Footer from './Footer'
import LifeLost from './LifeLost'
import Scores from './Scores'
import handleEvent from "./events"
import systems from "./systems"
import entities from "./entities"

import { 
	BACKGROUND_COLOR,
	EVENT_GAME_OVER,
	EVENT_LEVEL_FINISHED,
	EVENT_BONUS_CATCHED,
	EVENT_BONUS_TIME_REMAINING,
	EVENT_BONUS_END,

	BONUS_BASE_TIME,

	FOOTER_HEIGHT
} from './constants'

export default ({width, height, onGameOver}) => {

	const [ score, setScore ] = useState(0)
	const [ lost, setLost ] = useState(false)
	const [ finished, setFinished ] = useState(false)
	const [ life, setLife ] = useState(2)
	const [ killed, setKilled ] = useState([])
	const [ level, setLevel ] = useState(1)
	const [ bonus, setBonus ] = useState(null/*{fires: 2, multiplier: 2}*/)
	const [ bonusRemaining, setBonusRemaining] = useState(null)

	const handleLevelFinished = () => {
		setLevel(level+1)
		setFinished(true)
		setBonus(false)
		setKilled([])
	}

	const handleLostLife = () => {
		setLost(true)
		setBonus(false)
		setLife(life-1)
	}

	const handleKilled = (id) => {
		setKilled(old => {
			old.push(id)
			setKilled(old)
		})
	}

	if (finished) {
		return (
			<LevelFinished
				level={level-1}
				onNext={() => setFinished(false)}
			/>
		)
	}

	if (lost) {
		return (
			<LifeLost
				life={life}
				onNext={() => setLost(false) }
			/>
		)
	}

	return (
		<div style={{position: "relative", height: height + FOOTER_HEIGHT}}>
			<GameEngine
				key={`game-${level}`}
				style={{ width, height: height, backgroundColor: BACKGROUND_COLOR }}
				systems={systems({ width, height, alreadyKilled: killed, level })}
				onEvent={event => {
					if (event.type === EVENT_GAME_OVER && onGameOver) {
						if (life > 0) {
							handleLostLife()
						} else {
							onGameOver({level, score})
						}
					} else if (event.type === EVENT_LEVEL_FINISHED) {
						handleLevelFinished()
					} else if (event.type === EVENT_BONUS_CATCHED) {
						setBonusRemaining(BONUS_BASE_TIME)
						setBonus(event.bonus.typology)
					} else if (event.type === EVENT_BONUS_TIME_REMAINING) {
						setBonusRemaining(event.remaining)
					} else if (event.type === EVENT_BONUS_END) {
						setBonus(null)
					}
					handleEvent(event, { score: { score, setScore }, life: { life, setLife }, bonus, killed: { addKilled: handleKilled } })
				}}
				entities={entities({ width, height })}>
			</GameEngine>
			<Scores
				level={level}
				score={score}
				life={life}
				width={width}
			/>
			<Footer
				bonus={{bonus, remaining: bonusRemaining}}
			/>
		</div>
	)
}

