
import {
	E_BULLET,
	E_INVADER,
	E_MONSTER,
	E_BONUS
} from "../constants"

export default function entities() {
	const entities = {
		get(index) {
			return Object.values(this)[index]
		}
	}

	Object.defineProperty(entities, "bullets", {
		get() {
			return Object.values(this).filter(entity => entity.type === E_BULLET)
		}
	})

	Object.defineProperty(entities, "invaders", {
		get() {
			return Object.values(this).filter(entity => entity.type === E_INVADER)
		}
	})

	Object.defineProperty(entities, "monsters", {
		get() {
			return Object.values(this).filter(entity => entity.type === E_MONSTER)
		}
	})

	Object.defineProperty(entities, "bonuses", {
		get() {
			return Object.values(this).filter(entity => entity.type === E_BONUS)
		}
	})

	Object.defineProperty(entities, "length", {
		get() {
			return Object.values(this).length
		}
	})

	return Object.create(entities)
}
