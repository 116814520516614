
import entity from "./entity"
import position from "../components/position"
import borders from "../components/borders"
import dimensions from "../components/dimensions"
import velocity from "../components/velocity"
import playground from "../components/playground"

export default function grid({ id, gridPosition, gridDimensions, playgroundDimensions }) {
	return entity(id)
		.addComponent(velocity, 20)
		.addComponent(position, gridPosition)
		.addComponent(dimensions, gridDimensions)
		.addComponent(borders)
		.addComponent(playground, playgroundDimensions)
}
