import bonus from "../entities/rendered-bonus"
import moveBonuses from "./bonuses/move"

import {
	E_HERO,
	EVENT_BONUS_END,
	EVENT_BONUS_TIME_REMAINING,

	BONUS_BASE_TIME
} from '../constants'

export default function Bonuses({ width, height, level }) {
	return function Bonuses(entities, { input, dispatch }) {

		if (entities.bonuses.length !== 0) {
			moveBonuses(entities, { width, height })
		}

		entities.bonuses.forEach(bonus => {
			if (bonus.y >= height) {
				delete(entities[bonus.id])
			}
		})

		if (entities[E_HERO].bonus) {
			const diff = ((new Date()) - entities[E_HERO].bonus.startAt) / 1000
			if (diff >= BONUS_BASE_TIME) {
				entities[E_HERO].bonus = null
				dispatch({
					type: EVENT_BONUS_END
				})
			}
			dispatch({
				type: EVENT_BONUS_TIME_REMAINING,
				remaining: BONUS_BASE_TIME - Math.floor(diff)
			})
		}

		return entities
	}
}
