export const MAIN_WIDTH = 520 //800
export const MAIN_HEIGHT = 390 //600

export const E_HERO = "hero"
export const E_BULLET = "bullet"
export const E_ENNEMY = "ennemy"
export const E_GRID = "grid"
export const E_INVADER = "invader"
export const E_MONSTER = "monster"
export const E_BONUS = "bonus"

export const EVENT_COLLISION = "collision"
export const EVENT_GAME_OVER = "game-over"
export const EVENT_LEVEL_FINISHED = "level-finished"
export const EVENT_MONSTER_KILLED = "monster-killed"
export const EVENT_BONUS_CATCHED = "bonus-catched"
export const EVENT_BONUS_END = "bonus-end"

export const EVENT_BONUS_TIME_REMAINING = "bonus-time-remaining"

export const BONUS_BASE_TIME = 15

export const COMPONENT_ACTOR = "actor"
export const COMPONENT_ENNEMY = "ennemy"

export const K_SPACE = " "
export const KEY_SPACE = K_SPACE
export const KEY_DOWN = "ArrowDown"
export const KEY_UP = "ArrowUp"
export const KEY_LEFT = "ArrowLeft"
export const KEY_RIGHT = "ArrowRight"
export const KEY_S = "s"

export const HERO_WIDTH = 28 //13.5 //28
export const HERO_HEIGHT = 37 //18 //37

export const INVADER_WIDTH = 13.5 //28
export const INVADER_HEIGHT = 18
export const INVADERS_NUMBER = 8
export const INVADERS_COLORS = [
	"#fff",
	"#6498AE",
	"#AA0286",
	"#FB7105",
	"#cc0000"
]

export const INVADER_WIDTH_RATIO = 0.025

export const STEP_HOME = "home"
export const STEP_CHECK_HIGH_SCORES = "check-scores"
export const STEP_GAME = "game"
export const STEP_GAME_OVER = "gameover"
export const STEP_HIGH_SCORES = "high-score"

export const DIRECTION_LEFT = "left"
export const DIRECTION_RIGHT = "right"
export const DIRECTION_NONE = "none"

export const BACKGROUND_COLOR = "black" //"transparent"

export const BULLET_HERO_TYPE = "hero-bullet"
export const BULLET_INVADER_TYPE = "invader-bullet"

export const INVADER_FIRE_MIN = 1000
export const INVADER_FIRE_MAX = 5000

export const ENV_DEV = "development"

export const FOOTER_HEIGHT = 30
