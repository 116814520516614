import monster from "./monster"
import Monster from "../renderers/monster"
import renderer from "../components/renderer"

export default function renderedMonster({ id, monsterPosition, playgroundDimensions, monsterTypology }) {
	const monsterEntity = monster({ id, monsterPosition, playgroundDimensions, monsterTypology })

	return monsterEntity
		.addComponent(renderer, Monster)
}
