import React from "react"

import {
	FOOTER_HEIGHT
} from './constants'

export default ({bonus: { bonus, remaining }}) => (
		<Bonus
			bonus={bonus}
			remaining={remaining}
		/>
)

const Bonus = ({bonus, remaining}) => (
	<div style={{ height: FOOTER_HEIGHT, display: "flex", alignItems: "center", justifyContent: "flex-end", color: "#fff", marginTop: -10}}>
		{ bonus && (
				<>
					<div style={{backgroundColor: "#fff", color: "black", borderRadius: 100, width: 30, height: FOOTER_HEIGHT, display: "flex", alignItems: "center", justifyContent: "center", marginRight: 10}}>{remaining}</div>
					<div
						style={{width: 20, height: 20, marginRight: 10, display: "flex", alignItems: "center", justifyContent: "center"}}
						dangerouslySetInnerHTML={{ __html: bonus.picto }}
					/>
					<strong style={{margin: 10, opacity: bonus.multiplier > 1 ? 1 : 0.7}}>x{bonus.multiplier}</strong>
				</>
		)}
	</div>
)
