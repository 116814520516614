
import {
	DIRECTION_NONE
} from "../constants"

export default function direction(entity, direction = DIRECTION_NONE) {
	entity.direction = direction

	return entity
}
