
import {
	E_HERO
} from "../constants"

import hero from "./rendered-hero"
import initEntities from "./entities"

export default function entities({ width, height }) {
	const entities = initEntities()

	entities[E_HERO] =  hero({ width, height })

	return entities
}
