
import {
	EVENT_COLLISION,
	EVENT_BONUS_CATCHED,

	EVENT_LEVEL_FINISHED,
	EVENT_GAME_OVER,

	E_HERO,
	E_MONSTER,

	BULLET_HERO_TYPE
} from "../constants"

import bonusBuilder from '../entities/rendered-bonus'

import { getBonus } from '../bonus.js'

export default function collision (entities, { input, dispatch }) {

	entities.bullets.forEach(bullet => {
		handleBullet({ bullet, entities, dispatch })
	})

	entities.bonuses.forEach(bonus => {
		handleBonus({ bonus, entities, dispatch })
	})

  return entities
}

function handleBullet({ bullet, entities, dispatch }) {
	if (bullet.from.from === BULLET_HERO_TYPE) {
		entities.invaders.concat(entities.monsters).forEach(ennemy => {
			handleBulletAndEnnemy({ bullet, ennemy, entities, dispatch })
		})
	} else {
		handleBulletAndHero({ bullet, hero: entities[E_HERO], entities, dispatch })
	}
}

function handleBulletAndEnnemy({ bullet, ennemy, entities, dispatch }) {
	if (!ennemy.canBeTouched && !isTouched(bullet, ennemy)) {
		return
	}

	if (!ennemy.typology.touches.includes(bullet.id)) {
		ennemy.typology.touches.push(bullet.id)
		delete(entities[bullet.id])
	}

	if (ennemy.typology.lifes > ennemy.typology.touches.length) {
		return
	}

	dispatch({
		type: EVENT_COLLISION,
		ennemy
	})

	if (ennemy.type === E_MONSTER) {
		const bonus = bonusBuilder({monster: ennemy, bonusTypology: getBonus()})
		entities[bonus.id] = bonus
	}

	delete(entities[ennemy.id])
	delete(entities[bullet.id])

	if (entities.invaders.length === 0) {
		dispatch({
			type: EVENT_LEVEL_FINISHED,
		})
	}
}

function handleBulletAndHero({ bullet, hero, entities, dispatch }) {
	if (!isTouched(bullet, hero)) {
		return
	}

	dispatch({
		type: EVENT_GAME_OVER
	})

	delete(entities[bullet.id])
}

function handleBonus({ bonus, entities, dispatch }) {
	if (!isTouched(bonus, entities[E_HERO])) {
		return
	}

	entities[E_HERO].bonus = { ...bonus.typology, startAt: bonus.startAt }

	dispatch({
		type: EVENT_BONUS_CATCHED,
		bonus
	})

	delete(entities[bonus.id])
}

export function isTouched(entity1, entity2) {
	if (
		isWithin(entity1.borders.topLeft, entity2.borders)
		|| isWithin(entity1.borders.topRight, entity2.borders)
		|| isWithin(entity1.borders.bottomLeft, entity2.borders)
		|| isWithin(entity1.borders.bottomRight, entity2.borders)
	) {
		return true
	}

	return false
}

export function isWithin({ x, y }, { top, bottom, left, right }) {
	if (
		x >= left
		&& x <= right
		&& y >= top
		&& y <= bottom
	) {
		return true
	}

	return false
}
