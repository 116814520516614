
class Playground {
	constructor(entity, { width, height }) {
		this.width = width
		this.height = height

		this.entity = entity
	}
}

export default function playground(entity, playground) {
	entity.playground = new Playground(entity, playground)

	return entity
}
