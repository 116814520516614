import React, { useEffect, useState } from "react"
import HighScores from './HighScores'

import { BACKGROUND_COLOR } from './constants'

export default ({width, height, level, score, onNext}) => {

	const [ waitingFor, setWaitingFor ] = useState(true)

	useEffect(() => {
		setTimeout(() => {
			setWaitingFor(false)
		}, 2000)
		return () => {
			document.removeEventListener("keydown", handleKeyPressed, false)
		}
	}, [])

	useEffect(() => {
		if (!waitingFor) {
			document.addEventListener("keydown", handleKeyPressed, false)
		} else {
			document.removeEventListener("keydown", handleKeyPressed, false)
		}
	}, [waitingFor])

	const handleKeyPressed = (event) => {
		if (event.code === "Space") {
			document.removeEventListener("keydown", handleKeyPressed, false)
			onNext()
		}
	}

	return (
		<div style={{width, height, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: BACKGROUND_COLOR, color: "white"}}>
			<div style={{fontSize: "2em"}}>GAME OVER</div>
			<div style={{fontSize: "1em"}}><strong>{score}</strong> pts at Level <strong>{level}</strong></div>
			<HighScores />
			<div style={{height: 20}}>
				{ !waitingFor && (
						<span>Press <strong>SPACE</strong> to replay</span>
				)}
			</div>
		</div>
	)

}
