import { 
	INVADERS_COLORS
} from '../../constants'

import { getConfig } from '../../config'

class Component {
	constructor(entity, typology) {

		let { lifes, canFire, value, picto } = getConfig("invaders")[typology]

		this.lifes = lifes
		this.touches = []
		this.canFire = canFire

		this.value = value
		this.picto = picto
/*
		if (typology >= 5) {
			this.lifes = typology - 3
		}
		*/

		this.entity = entity
}
}

export default function typology(entity, typology) {
	entity.typology = new Component(entity, typology)
	return entity
}
