var i = 0

export default function moveMonsters(entities, { reset }) {

	if (reset) i=0
	i++

	entities.monsters.forEach(monster => {
		if (monster.typology.popTime === i && !monster.used) {
			monster.active = true
		}
		if (i % monster.velocity === 0) {
			if (monster.active) {
				monster.x -= 10
			}
			if (monster.x <= 0) {
				monster.active = false
				monster.used = true
				monster.x = 520
			}
		}
	})

	return
}
