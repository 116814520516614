import React, { useEffect, useState } from "react"

import HighScoreForm from "./ui/scores/form"

import { BACKGROUND_COLOR } from './constants'

export default ({ width, height, score, onNext }) => {

	const [ loading, setLoading] = useState(true)
	const [ position, setPosition] = useState(99999)

	const handleScoreSubmitted = () => {
		onNext()
	}

	useEffect(() => {
		fetch("https://x8ki-letl-twmt.n7.xano.io/api:bJjomHgU/scores")
			.then(response => response.json())
			.then(scores => {
				const upScores = scores.filter(s => s.score >= score)
				if (upScores.length < 10) {
					setPosition(upScores.length + 1)
					Promise.all(scores.sort((a, b) => a.score > b.score ? -1 : 1).slice(9).map(s => {
						return fetch(`https://x8ki-letl-twmt.n7.xano.io/api:bJjomHgU/scores/${s.id}`, {
							method: "DELETE"
						})
					}))
						.then(() => setLoading(false))
				} else {
					onNext()
				}
			})
	}, [])

	if (loading) {
		return (
			<div style={{color: "#fff"}}>Checking high scores</div>
		)
	}

	return (
		<div style={{width, height, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: BACKGROUND_COLOR, color: "white"}}>
			<span style={{fontSize: "2em"}}>HIGH SCORE !</span>
			<span style={{fontSize: "1.5em"}}>Your position <strong>{position}</strong> with <strong>{score}</strong> pts</span>
			<HighScoreForm score={score} onSubmitted={handleScoreSubmitted} />
			<a href="javascript:void(0)" style={{color: "#fff"}} onClick={onNext}>Passer</a>
		</div>
	)
}
