
import React, { PureComponent } from "react"

import {
	E_BULLET
} from "../constants"

class Bullet extends PureComponent {
  render() {
		const entity = this.props.renderer.props

		const x = this.props.x - entity.dimensions.width / 2
		const y = this.props.y - entity.dimensions.height / 2

    return (
      <div style={{ position: "absolute", width: entity.dimensions.width, height: entity.dimensions.height, backgroundColor: "#fff", left: x, top: y }} />
    );
  }
}

Bullet.defaultProps = {
	type: E_BULLET
}

export default Bullet
