import React, { useState } from "react"

import './form.css'

const translations = {
	surname: "Nom",
	email: "Email",
	company: "Entreprise"
}

export default ({score, onSubmitted}) => {

	const [values, setValues] = useState({
		surname: "",
		email: "",
		company: ""
	})
	const [error, setError] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const handleChange = (name, value) => {
		setValues(old => {
			let newV = { ...old }
			newV[name] = value
			return newV
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		let error = false
		if (Object.values(values).filter(v => v == "").length > 0) {
			setError("Tous les champs sont obligatoires")
			error = true
		}

		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
		if (!pattern.test(values["email"])) {
			setError("Email invalide")
			error = true
		}

		if (!error) {
			setError(false)
			setSubmitting(true)
			fetch("https://x8ki-letl-twmt.n7.xano.io/api:bJjomHgU/scores", {
				method: "POST",
				body: JSON.stringify({ ...values, score}),
				headers: { 'Content-Type': 'application/json' }
			})
				.then(res => res.json())
				.then(json => console.log(json))
				.then(() => onSubmitted())
		}
	}

	return (
		<form>
			{ Object.keys(values).map(name => (
				<div className="form-control">
					<label for={name}>{translations[name]} <span>*</span></label>
					<input type="text" name={name} value={values[name]} onChange={({target: { value }}) => handleChange(name, value)} />
				</div>
			))}
			{ error && (
					<div className="error">{error}</div>
			)}
			<button onClick={handleSubmit}>Push in High Scores</button>
		</form>
	)
}
