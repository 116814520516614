
import React, { PureComponent } from "react"

class Hero extends PureComponent {
	render() {
		const entity = this.props.renderer.props

		const x = this.props.x - 45/*entity.dimensions.width*/ / 2
		const y = this.props.y - 18.5/*entity.dimensions.height*/ / 2

		return (
			<div style={{
					position: "absolute",
					width: entity.dimensions.width,
					height: entity.dimensions.height,
					left: x,
					top: y,
					//transform: "rotate(180deg)"
				}}
				className="hero-block"
				dangerouslySetInnerHTML={{ __html: (entity.bonus && entity.bonus.picto) ? entity.bonus.picto : defaultPicto }}
			/>
		)
	}
}

export const HeroPicto = ({opacity, width, height}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width || "45px"} height={height || "18.5px"} viewBox="0 0 220 90" style={{fill: "#fff", opacity: 1}}>
		<polygon points="79.92 20.52 79.92 39.3 13.31 39.3 0 54.15 0 90 220 90 220 54.15 206.69 39.3 140.08 39.3 140.08 20.52 79.92 20.52" />
		<rect className="canon" x="101.09" width="17.83" height="39.76" />
	</svg>
)

const defaultPicto = '<svg xmlns="http://www.w3.org/2000/svg" width="45px" height="18.5px" viewBox="0 0 220 90" style="fill: #fff; opacity: 1"><polygon points="79.92 20.52 79.92 39.3 13.31 39.3 0 54.15 0 90 220 90 220 54.15 206.69 39.3 140.08 39.3 140.08 20.52 79.92 20.52" /><rect className="canon" x="101.09" width="17.83" height="39.76" /></svg>'

export default Hero
