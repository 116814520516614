import {
	E_BONUS,
	INVADER_WIDTH,
	INVADER_WIDTH_RATIO,
	INVADER_HEIGHT
} from "../constants"

import entity from "./entity"
import typology from '../components/typologies/bonus'
import position from "../components/position"
import direction from "../components/direction"
import velocity from "../components/velocity"
import dimensions from "../components/dimensions"
import borders from "../components/borders"
import playground from "../components/playground"

export default function bonus({ id, monster, bonusVelocity = 10, bonusPosition, bonusWidth, bonusHeight, bonusTypology }) {
	const { width } = monster.playground

	const bonusDimensions = {
		width: 20, //bonusWidth || width * INVADER_WIDTH_RATIO,
		height: 20 //bonusHeight || width * INVADER_WIDTH_RATIO * (INVADER_HEIGHT / INVADER_WIDTH)
	}

	return entity(E_BONUS, `bonus-${monster.id}`)
		.addComponent(position, { x: monster.x, y: monster.y })
		.addComponent(velocity, 10)
		.addComponent(dimensions, bonusDimensions)
		.addComponent(typology, bonusTypology)
		.addComponent(borders)
		.addComponent(playground, monster.playground)
}
