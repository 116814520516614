
import hero from "./hero"
import Hero from "../renderers/hero"
import renderer from "../components/renderer"
import borders from "../components/borders"

export default function renderedHero({ width, height }) {
	const heroEntity = hero({ width, height})

	return heroEntity
		.addComponent(renderer, Hero)
		.addComponent(borders, heroEntity)
}
