var i = 0

export default function moveBonuses(entities) {

	i++

	entities.bonuses.forEach(bonus => {
		if (i % bonus.velocity === 0) {
			bonus.y += 10
		}
	})

	return
}
