
class From {
	constructor(entity, from) {
		this.from = from
		this.entity = entity
	}
}

export default function from(entity, from) {
	entity.from = new From(entity, from)
	return entity
}
