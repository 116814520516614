
import invader from "./invader"
import Invader from "../renderers/invader"
import renderer from "../components/renderer"

export default function renderedInvader({ id, invaderPosition, playgroundDimensions, invaderTypology }) {
	const invaderEntity = invader({ id, invaderPosition, playgroundDimensions, invaderTypology })

	return invaderEntity
		.addComponent(renderer, Invader)
}
