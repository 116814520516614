import React, { useEffect, useState } from 'react'

export default ({level, onNext}) => {

	const [ waitingFor, setWaitingFor ] = useState(true)

	useEffect(() => {
		setTimeout(() => {
			setWaitingFor(false)
		}, 2000)
		return () => {
			document.removeEventListener("keydown", handleKeyPressed, false)
		}
	}, [])

	useEffect(() => {
		if (!waitingFor) {
			document.addEventListener("keydown", handleKeyPressed, false)
		} else {
			document.removeEventListener("keydown", handleKeyPressed, false)
		}
	}, [waitingFor])

	const handleKeyPressed = (event) => {
		if (event.code === "Space") {
			document.removeEventListener("keydown", handleKeyPressed, false)
			onNext()
		}
	}

	return (
		<div style={{position: "relative", color: '#fff', display: "flex", flexDirection: "column", alignItems: "center"}}>
			<span style={{fontSize: "2.5em"}}>LEVEL <strong>{level}</strong> FINISHED</span>
			<div style={{display: "flex", fontSize: "2em", alignItems: "center", padding: "20px"}}>
				Well Done !
			</div>
			<div style={{padding: "20px", height: 23}}>
				{ !waitingFor && (
						<>
							Press <strong>SPACE</strong> to continue
						</>
				)}
			</div>
		</div>
	)
}
