import grid from "../../entities/grid"

import matrices, { inMatrix } from './matrices'

import {
	E_GRID,
	E_INVADER,
	INVADER_WIDTH_RATIO
} from "../../constants"

export default function initInvaders(entities, { width, height, entityBuilder, startX = 40, startY = 80, invaderWidth, invaderHeight, alreadyKilled, level }) {
	const invaders = []

	let id = 0, rowIndex = 0, columnIndex = 0
	let invaderPosition = 0, invaderEntity = 0
	let matrix = []

	for (let invaderId = 0; invaderId < 128; invaderId++) {

		id = `${E_INVADER}-${invaderId}`
		rowIndex = Math.floor(invaderId / 16)
		columnIndex = invaderId % 16 

		matrix = matrices(level)

		if (!alreadyKilled.includes(id) && inMatrix(matrix, rowIndex, columnIndex)) {
			invaderPosition = buildInvaderConfig({ width, height, index: invaderId, startX, startY })

			invaderEntity = entityBuilder({ id, playgroundDimensions: { width, height }, invaderPosition, invaderWidth, invaderHeight, invaderTypology: matrix[rowIndex][columnIndex] })
			invaders.push(invaderEntity)
			entities[id] = invaderEntity
		}
	}

	const first = invaders[0]
	const last = invaders[invaders.length - 1]

	entities[E_GRID] = grid({
		id: E_GRID,
		gridPosition: {
			x: (first.x + last.x) / 2,
			y: (first.y + last.y) / 2,
		},
		gridDimensions: {
			width: last.x - first.x + first.dimensions.width / 2 + last.dimensions.width / 2,
			height: last.y - first.y + first.dimensions.height / 2 + last.dimensions.height / 2
		},
		playgroundDimensions: { width, height }
	})

	return entities
}

function buildInvaderConfig({ width, height, index, startX, startY }) {
	const invaderWidth = width * INVADER_WIDTH_RATIO
	const widthOccupation = width * 0.8

	const invaderPerLine = widthOccupation / invaderWidth / 2
	const positionInLine = index % invaderPerLine
	const currentLine = Math.floor(index / invaderPerLine)

	return {
		x: startX + (positionInLine * invaderWidth * 2),
		y: startY + (currentLine * invaderWidth * 2)
	}
}
