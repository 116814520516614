import bonus from "./bonus"
import Bonus from "../renderers/bonus"
import startAt from "../components/start-at"
import renderer from "../components/renderer"

export default function renderedBonus({ id, monster, bonusTypology }) {
	const bonusEntity = bonus({ id, monster, bonusTypology })

	return bonusEntity
		.addComponent(renderer, Bonus)
		.addComponent(startAt)
}
