
import Bullet from "../renderers/bullet"

import entity from "../entities/entity"
import renderer from "../components/renderer"
import position from "../components/position"
import dimensions from "../components/dimensions"
import borders from "../components/borders"
import from from "../components/from"

import {
	KEY_SPACE,
	E_HERO,
	E_BULLET,

	BULLET_HERO_TYPE,
	BULLET_INVADER_TYPE,

	INVADER_FIRE_MIN,
	INVADER_FIRE_MAX
} from "../constants"

import { onKeyDown } from "./events"

var times = require('lodash.times')

let bulletId = 0
let canFire = true, invaderCanFire = true
let randomInvader = null

export default function Fire({ width, height }) {
	return function Fire(entities, { input, dispatch }) {
		if (onKeyDown(KEY_SPACE, input)) {
			fire(entities, { width, height, fromEntity: BULLET_HERO_TYPE, dispatch })
		}

		const invaders = Object.values(entities).filter(e => e.type === "invader" && e.typology.canFire)
		if (randomInvader == null) {
			randomInvader = false
			setTimeout(() => { randomInvader = true }, Math.random() * (INVADER_FIRE_MAX - INVADER_FIRE_MIN) + INVADER_FIRE_MIN)
		} else if (randomInvader) {
			randomInvader = invaders[Math.floor(Math.random() * invaders.length)]
			if (randomInvader) {
				fire(entities, { width, height, fromEntity: BULLET_INVADER_TYPE, invader: randomInvader, dispatch })
			}
		}

		entities.bullets.forEach(bullet => {

			if (bullet.from.from === BULLET_HERO_TYPE) {
				if (bullet.y < 0) {
					delete(entities[bullet.id])
					return
				}
				bullet.y = bullet.y - 2
			} else {
				if (bullet.y >= height) {
					delete(entities[bullet.id])
					return
				}
				bullet.y = bullet.y + 2
			}
		})

  	return entities
	}
}

function fire(entities, { width, height, fromEntity, invader, dispatch }) {
	if (fromEntity === BULLET_HERO_TYPE) {
		if (!canFire) {
			return
		} else {
			canFire = false
		}
	}

	if (fromEntity === BULLET_INVADER_TYPE) {
		if (!invaderCanFire) {
			return
		} else {
			invaderCanFire = false
		}
	}

	const item = fromEntity === BULLET_HERO_TYPE ? entities[E_HERO] : invader

	bulletId++
	if (item.bonus) {
		times(item.bonus.fires).map((i, index) => launch({width, height, item, entities, fromEntity, count: item.bonus.fires, index: index+1}))
	} else {
		launch({width, height, item, entities, fromEntity})
	}
	
	dispatch({
		type: "fire"
	})

	if (fromEntity === BULLET_HERO_TYPE) {
		setTimeout(() => {
			canFire = true
		}, 300)
	} else {
		setTimeout(() => {
			invaderCanFire = true
		}, Math.random() * (INVADER_FIRE_MAX - INVADER_FIRE_MIN) + INVADER_FIRE_MIN)
	}
}

function launch({width, height, item, entities, fromEntity, count=1, index=1}) {
	bulletId++
	const bulletConfig = buildBulletConfig({ width, height, item, count, index })
	const id = `${E_BULLET}-${bulletId}`

	entities[id] = entity(E_BULLET, id)
		.addComponent(position, {
			x: bulletConfig.position.x,
			y: bulletConfig.position.y,
		})
		.addComponent(dimensions, {
			width: bulletConfig.dimensions.width,
			height: bulletConfig.dimensions.height,
		})
		.addComponent(from, fromEntity)
		.addComponent(borders)
		.addComponent(renderer, Bullet)

}

function buildBulletConfig({ width, height, item, count, index }) {
	const dimensions = {
		width: 4,
		height: 10
	}
	let position = {
		y: item.y - (item.dimensions.height / 2),
		x: item.x
	}

	gunLocalizer(position, item, count, index)

	return {
		dimensions,
		position
	}
}

function gunLocalizer(position, item, count, index) {
	switch(count) {
		case 1:
			break
		case 2:
			if (index == 1) {
				position.x = item.x - (item.dimensions.width / 4)
			} else {
				position.x = item.x + (item.dimensions.width / 4)
			}
			break
		case 3:
			if (index == 1) {
				position.x = item.x - (item.dimensions.width / 3)
			} else if (index == 3) {
				position.x = item.x + (item.dimensions.width / 3)
			}
			break
	}

	return position
}
