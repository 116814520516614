
export default function borders(entity) {
	if (!entity.position || !entity.dimensions) {
		throw new Error("Entity must have position and dimensions component")
	}

	Object.defineProperty(entity, "borders", {
		get() {
			const borders = {
				right: entity.x + entity.dimensions.width / 2,
				left: entity.x - entity.dimensions.width / 2,
				top: entity.y - entity.dimensions.height / 2,
				bottom: entity.y + entity.dimensions.height / 2
			}

			borders.topLeft = { x: borders.left, y: borders.top }
			borders.topRight = { x: borders.right, y: borders.top }
			borders.bottomLeft = { x: borders.left, y: borders.bottom }
			borders.bottomRight = { x: borders.right, y: borders.bottom }

			return borders
		}
	})

	return entity
}
