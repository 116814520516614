
import {
	DIRECTION_LEFT,
	DIRECTION_RIGHT,
	E_GRID
} from "../../constants"

let i = 0

export default function moveInvaders(entities) {

	const invaders = entities.invaders
	const grid = entities[E_GRID]

	i++

	if (i % grid.velocity !== 0) {
		return
	}

	if (manageDirection(grid, invaders)) {
		return entities
	}

	invaders.forEach(moveInvader)


	return entities
}

export function moveInvader(invader) {
	if (invader.direction === DIRECTION_RIGHT) {
		invader.x = invader.x + invader.velocity
	}

	if (invader.direction === DIRECTION_LEFT) {
		invader.x = invader.x - invader.velocity
	}
}

function manageDirection(grid, invaders) {
	moveGrid(grid, invaders)

	const first = invaders[0]
	const direction = first.direction

	if (grid.borders.right >= grid.playground.width - 20 && direction === DIRECTION_RIGHT) {
		turn(direction, invaders)
		moveToBottom(invaders.concat(grid))
		increaseGridVelocity(grid)

		return true
	}

	if (grid.borders.left <= 20 && direction === DIRECTION_LEFT) {
		turn(direction, invaders)
		moveToBottom(invaders.concat(grid))
		increaseGridVelocity(grid)

		return true
	}

	return false
}

function turn(direction, invaders) {
	let nextDirection = DIRECTION_LEFT
	if (direction === DIRECTION_LEFT) {
		nextDirection = DIRECTION_RIGHT
	}

	invaders.forEach(invader => invader.direction = nextDirection)
}

function moveToBottom(items) {
	items.forEach(item => item.y = item.y + 20)
}

function increaseGridVelocity(grid) {
	if (grid.velocity <= 2) {
		return
	}

	grid.velocity = grid.velocity - 2
}

function moveGrid(grid, invaders) {
	const topInvader = findTopInvader(invaders)
	const bottomInvader = findBottomInvaders(invaders)
	const leftInvader = findLeftInvader(invaders)
	const rightInvader = findRightInvader(invaders)

	grid.x = (leftInvader.x + rightInvader.x) / 2
	grid.y = (topInvader.y + bottomInvader.y) / 2

	grid.dimensions.width = rightInvader.x - leftInvader.x + leftInvader.dimensions.width / 2 + rightInvader.dimensions.width / 2
	grid.dimensions.height = bottomInvader.y - topInvader.y + topInvader.dimensions.height / 2 + bottomInvader.dimensions.height / 2
}

function findTopInvader(invaders) {
	let current = invaders[0]

	invaders.forEach(invader => {
		if (invader.y < current.y) {
			current = invader
		}
	})

	return current
}

export function findBottomInvaders(invaders) {
	let current = invaders[0]

	invaders.forEach(invader => {
		if (invader.y > current.y) {
			current = invader
		}
	})

	return current
}

function findLeftInvader(invaders) {
	let current = invaders[0]

	invaders.forEach(invader => {
		if (invader.x < current.x) {
			current = invader
		}
	})

	return current
}

function findRightInvader(invaders) {
	let current = invaders[0]

	invaders.forEach(invader => {
		if (invader.x > current.x) {
			current = invader
		}
	})

	return current
}
