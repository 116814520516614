import React, { useState } from 'react'
import './App.css'
import Game from './Game'
import Home from './Home'
import HighScoresChecker from './HighScoresChecker'
import GameOver from './GameOver'
import HighScores from './HighScores'
import {
	STEP_HOME,
	STEP_GAME,
	STEP_CHECK_HIGH_SCORES,
	STEP_GAME_OVER,
	STEP_HIGH_SCORES,
	MAIN_WIDTH,
	MAIN_HEIGHT,
	BACKGROUND_COLOR
} from './constants'

export default () => {

	const [step, setStep] = useState(STEP_HOME)
	const [score, setScore] = useState(0)
	const [level, setLevel] = useState(1)

	const handleGameOver = ({level: l, score: s}) => {
		setScore(s)
		setLevel(l)
		setStep(STEP_CHECK_HIGH_SCORES)
		/*setTimeout(() => {
			setStep(STEP_HOME)
		}, 1500)
		*/
	}

	let component = null
	switch(step) {
		case STEP_HOME:
			component = (
				<Home
					width={MAIN_WIDTH}
					height={MAIN_HEIGHT}
					play={() => setStep(STEP_GAME)}
					highScores={() => setStep(STEP_HIGH_SCORES)}
				/>
			)
			break
		case STEP_GAME:
			component = (
				<Game
					width={MAIN_WIDTH}
					height={MAIN_HEIGHT}
					onGameOver={handleGameOver}
				/>
			)
			break
		case STEP_CHECK_HIGH_SCORES:
			component = (
				<HighScoresChecker
					score={score}
					onNext={() => setStep(STEP_GAME_OVER)}
				/>
			)
			break
		case STEP_GAME_OVER:
			component = (
				<GameOver
					width={MAIN_WIDTH}
					height={MAIN_HEIGHT}
					level={level}
					score={score}
					onNext={() => setStep(STEP_GAME)}
				/>
			)
			break
			case STEP_HIGH_SCORES:
				component = (
					<div style={{width: MAIN_WIDTH, height: MAIN_HEIGHT, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", background: BACKGROUND_COLOR, color: "white"}}>
						<HighScores onNext={() => setStep(STEP_GAME)} />
					</div>
				)
			break
		default:
			component = null
	}

	return (
		<div style={{background: BACKGROUND_COLOR, position: "absolute", top: 0, right: 0, left: 0, bottom: 0, display: "flex", alignItems: "center", justifyContent: "center"}}>
			{component}
		</div>
	)
}
