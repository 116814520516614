class Bonus {
	constructor(entity, { fires=1 }) {
		this.fires = fires

		this.entity = entity
	}
}

export default function bonus(entity, bonus) {
	entity.bonus = new Bonus(entity, bonus)

	return entity
}
