
import {
	EVENT_COLLISION,
	EVENT_BONUS_CATCHED
} from "../constants"

const handlers = [
	scoreHandler
]

export default function handleEvent(event, state) {
	handlers.forEach(handler => handler(event, state))
}

function scoreHandler(event, state) {
	if (event.type !== EVENT_COLLISION && event.type !== EVENT_BONUS_CATCHED) {
		return
	}

	const { score: { score, setScore }, life: { life, setLife }, bonus, killed: { addKilled } } = state

	switch(event.type) {
		case EVENT_COLLISION:
			let scoreToAdd = event.ennemy.typology.value
			if (bonus) scoreToAdd *= bonus.multiplier
			setScore(score + scoreToAdd)
			if (Math.floor(score / 1000) < Math.floor((score + event.ennemy.typology.value) / 1000)) {
				setLife(life+1)
			}
			addKilled(event.ennemy.id)
			break
		case EVENT_BONUS_CATCHED:
			setScore(score + event.bonus.typology.value)
			break
	}
}
