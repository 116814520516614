import { getConfig } from '../../config'

class Component {
	constructor(entity, { typology, popTime }) {

		let { lifes, canFire, value, picto } = getConfig("monsters")[typology]

		this.lifes = lifes
		this.touches = []
		this.canFire = canFire
		this.picto = picto
		this.value = value
		this.popTime = parseInt(popTime)
	}
}

export default function typology(entity, typology) {
	entity.typology = new Component(entity, typology)
	return entity
}
