import { getLevel } from "../../levels"

export default function initMonsters(entities, { width, height, entityBuilder, startX = 40, startY = 80, level }) {
	const currentLevel = getLevel(level)
	if (currentLevel && currentLevel.monsters.length > 0) {
		let id
		currentLevel.monsters.forEach((monster, index) => {
			id = `monster-${index}`
			entities[id] = entityBuilder({ id, playgroundDimensions: { width, height }, monsterTypology: monster})
		})
	}

	return entities
}
