import monster from "../entities/rendered-monster"
import moveMonsters from "./monsters/move"
import initMonsters from "./monsters/init"

let started = false
let currentLevel = null

export default function Monsters({ width, height, level }) {
	return function Monsters(entities, { input, dispatch }) {

		if (!started) {
			moveMonsters(entities, { width, height, reset: true })
			started = true
			initMonsters(entities, { width, height, entityBuilder: monster, level })
		}

		if (entities.monsters.length === 0) {
			started = false
		}

		if (started && entities.monsters.length !== 0) {
			moveMonsters(entities, { width, height })
		}

		return entities
	}
}
