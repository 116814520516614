
class Dimensions {
	constructor(entity, { width, height }) {
		this.width = width
		this.height = height

		this.entity = entity
	}
}

export default function dimensions(entity, dimensions) {
	entity.dimensions = new Dimensions(entity, dimensions)

	return entity
}
