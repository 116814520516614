import React, { useEffect, useState } from "react"

import './HighScores.css'

export default ({ onNext }) => {

	const [ scores, setScores] = useState([])

	useEffect(() => {
		if (onNext) {
			document.addEventListener("keydown", handleKeyPressed, false)
		}
		fetch("https://x8ki-letl-twmt.n7.xano.io/api:bJjomHgU/scores")
			.then(response => response.json())
			.then(scores => setScores(scores.sort((a, b) => a.score > b.score ? -1 : 1)))

		if (onNext) {
			return () => {
				document.removeEventListener("keydown", handleKeyPressed, false)
			}
		}
	}, [])

	const handleKeyPressed = (event) => {
		if (event.code === "Space") {
			document.removeEventListener("keydown", handleKeyPressed, false)
			onNext()
		}
	}

	return (
		<>
			<div className="scores">
				<div className="scores-title">HIGH SCORES</div>
				{ scores.map((score, index) => (
						<div className="score">
							<div className="score-position">{index+1}.</div>
							<div className="score-surname">{score.surname}</div>
							<div className="score-score"><strong>{score.score}</strong> pts</div>
						</div>
				))}
			</div>
			{ onNext && (
					<span>Press <strong>SPACE</strong> to replay</span>
			)}
		</>
	)
}
