import Hero from "./hero"
import Fire from "./fire"
import Invaders from "./invaders"
import Monsters from './monsters'
import Bonuses from './bonuses'
import Collision from './collision'

export default function systems({ width, height, alreadyKilled, level }) {
	return [
		Fire({ width, height}),
		Hero,
		Invaders({ width, height, alreadyKilled, level }),
		Monsters({ width, height, level }),
		Bonuses({ width, height }),
		Collision
	]
}
