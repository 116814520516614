
import {
	E_INVADER,
	INVADER_WIDTH,
	INVADER_WIDTH_RATIO,
	INVADER_HEIGHT,
	DIRECTION_RIGHT
} from "../constants"

import entity from "./entity"
import typology from '../components/typologies/invader'
import position from "../components/position"
import direction from "../components/direction"
import velocity from "../components/velocity"
import dimensions from "../components/dimensions"
import borders from "../components/borders"
import playground from "../components/playground"

export default function invader({ id, invaderPosition, playgroundDimensions, invaderVelocity = 10, invaderWidth, invaderHeight, invaderTypology }) {

	const { width } = playgroundDimensions

	const invaderDimensions = {
		width: invaderWidth || width * INVADER_WIDTH_RATIO,
		height: invaderHeight || width * INVADER_WIDTH_RATIO * (INVADER_HEIGHT / INVADER_WIDTH)
	}

	return entity(E_INVADER, id)
		.addComponent(position, invaderPosition)
		.addComponent(direction, DIRECTION_RIGHT)
		.addComponent(velocity, invaderVelocity)
		.addComponent(dimensions, invaderDimensions)
		.addComponent(typology, invaderTypology)
		.addComponent(borders)
		.addComponent(playground, playgroundDimensions)
}
